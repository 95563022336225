import axios from "axios";
import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import Papa from "papaparse";

export const RequestProgressStatus = {
  Start: "START",
  End: "END",
};

export const RequestProgressEvent = "requestProgressEvent";

export const defaultErrorMessage = "Something went wrong. Please try again.";

export function useApiRequest(apiRequest, onMount = true) {
  const [loading, setLoading] = useState(onMount);
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const abortController = useRef();

  const sendRequest = async () => {
    setLoading(true);
    // cancel request if it's in-flight
    abortController.current?.abort();

    abortController.current = new AbortController();
    const signal = abortController.current.signal;

    apiRequest()
      .then((data) => {
        if (!signal.aborted) {
          setResponse(data);
        }
      })
      .catch((error) => {
        if (!signal.aborted) {
          setError(error);
          setResponse([]);
        }
      })
      .finally(() => {
        abortController.current = undefined;
        setLoading(false);
      });
  };

  const cancelRequest = () => {
    abortController.current?.abort();
    abortController.current = undefined;
  };

  useEffect(() => {
    onMount && sendRequest();
  }, []);

  return {
    loading,
    response,
    error,
    sendRequest,
    cancelRequest,
  };
}

const getUrlKey = (url, timeStamp) => `${url}_${timeStamp}`;
/**
 * Base class for remote HTTP calls
 */
class HttpClient {
  /**
   *
   * @param {string} baseUrl Base URL for your remote calls
   * @param {object} options options for the http client
   * @param {string[]} options.publicPaths Paths that do not require an auth token
   * @param {number} [options.timeout] Default timeout for the client
   * @param {object} dependencies deps for the http client
   * @param {LocalStorageCache} dependencies.tokenCache Cache holding the auth token
   */
  constructor(
    baseUrl,
    { publicPaths = [], timeout = 5000 } = {},
    { tokenCache, currentUserCache, appSettingsCache } = {},
    { progressTimeout = 1000, progressTimeoutHandler } = {}
  ) {
    this.abortControllers = new Map();
    this.singleRequestCache = new Map();
    this.requestTimers = new Map();
    this.progressTimeoutHandler = progressTimeoutHandler;
    this.http = axios.create({
      baseURL: baseUrl,
      timeout,
    });
    this.tokenCache = tokenCache;
    this.currentUserCache = currentUserCache;
    this.appSettingsCache = appSettingsCache;

    this.http.interceptors.request.use(
      (config) => {
        const abortController = new AbortController();
        const skipAuth = publicPaths.some((url) => url.includes(config.url));
        const timeStamp = Date.now();
        const timerId = setTimeout(
          () => progressTimeoutHandler?.(urlKey, RequestProgressStatus.Start),
          progressTimeout
        );
        const timeStamptHeader = `${timeStamp}_${timerId}`;
        const urlKey = getUrlKey(config.url, timeStamptHeader);

        this.requestTimers.set(urlKey, timerId);
        config.headers["TimeStamp"] = timeStamptHeader;

        config.signal = abortController.signal;
        this.abortControllers.set(config.url, abortController);

        if (skipAuth) {
          return config;
        }

        config.headers["Authorization"] = `Bearer ${this.token}`;

        if (!config.headers["organization"]) {
          config.headers["organization"] = this.organizationCode;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.http.interceptors.response.use(
      (response) => {
        const { config, data } = response;
        const { headers, url } = config;
        this.abortControllers.delete(url);
        this.clearTimer(getUrlKey(url, headers["TimeStamp"]));
        return data;
      },
      (error) => {
        console.log({ error });
        const { headers, url } = error.config || {};
        this.abortControllers.delete(url);
        this.clearTimer(getUrlKey(url, headers["TimeStamp"]));

        if (error.response.status === 498) {
          /**
           * Error code 498 is sent by dashboard api when an SSO user's refresh token (server session) has been revoked or expired.
           * Clear the session cache and return the user to root path in unauthenticated state.
           */

          currentUserCache.remove();
          tokenCache.remove();
          appSettingsCache.remove();
          window.location.href = window.location.origin;
        }
        return Promise.reject(error.response ? error.response : error);
      }
    );
  }

  get token() {
    return this.tokenCache.get();
  }

  get organizationCode() {
    return this.currentUserCache.get()?.organizationCode;
  }

  downloadBlob(config, fileName, fileExtension = "csv") {
    const requestConfig = { ...config, responseType: "blob" };
    return this.http.request(requestConfig).then((response) => {
      const blob = new Blob([response], { type: "text/plain;charset=utf-8" });
      const name = `${fileName ?? Date.now()}.${fileExtension}`;
      saveAs(blob, name);
      return true;
    });
  }

  parseCsvResponse(requestConfig) {
    return this.http.request(requestConfig).then((response) => {
      return Papa.parse(response, { header: true, skipEmptyLines: true });
    });
  }

  cancelRequest(url) {
    if (url) {
      this.abortControllers.get(url)?.abort();
      this.abortControllers.delete(url);
    } else {
      // Cancel all ongoing requests
      for (const [key, controller] of this.abortControllers.entries()) {
        controller.abort();
        this.abortControllers.delete(key);
      }
    }
  }

  cachedRequest(url, config) {
    if (!this.singleRequestCache.has(url)) {
      const request = this.http({ url, method: "get", ...config }).then(
        (response) => {
          this.singleRequestCache.delete(url);
          return response;
        }
      );
      this.singleRequestCache.set(url, request);
    }

    return this.singleRequestCache.get(url);
  }

  clearTimer(url) {
    const timerId = this.requestTimers.get(url) ?? undefined;
    clearTimeout(timerId);
    this.progressTimeoutHandler?.(url, RequestProgressStatus.End);
  }
}

export default HttpClient;
